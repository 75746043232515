<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items"/>
    <div class="mb-4 w-100 pedisteps-view mt-4 mt-sm-0">
      <div class="row">
        <div class="col-lg-3 order-1">
          <div class="p-3 border-bottom clickable" :class="{ active: isSelf }">
            <div @click="()=>selectPatient($store.state.user)" class="media pointer">
              <div class="align-self-center mr-3">
                <img :src="$store.state.user.image"
                     class="avatar-xs rounded-circle"
                     alt="My Profile Image"/>
              </div>
              <div class="media-body">
                <h5 class="font-size-13 mt-0 mb-1">
                  {{ $store.state.user.name }}</h5>
                <p class="text-muted mb-0">
                  <i class="mdi mdi-circle text-success align-middle mr-1"></i>
                  Active
                </p>
              </div>
            </div>
          </div>
          <div class="chat-leftsidebar-nav">
            <h5 class="font-size-14 px-3 my-3">{{ $t('pages.pedisteps.patients') }}</h5>
            <simplebar id="scrollPatients">
              <ul class="list-unstyled patient-list">
                <li v-for="(patient, k) of $store.state.monitor.patients"
                    :key="`patient-${k}`"
                    @click="selectPatient(patient)"
                    :class="{ active: selectedPatient.id === patient.id }"
                >
                  <a href="javascript: void(0);">
                    <div class="media">
                      <div
                          class="user-img align-self-center mr-3 away"
                          v-if="patient.image"
                          :class="{
                                online: `${patient.status}` === 'online',
                                away: `${patient.status}` === 'away',
                              }">
                        <img
                            :src="`${patient.image}`"
                            class="rounded-circle avatar-xs"
                            alt/>
                        <span class="user-status"></span>
                      </div>
                      <div class="user-img mr-3 away" v-if="!patient.image"
                           :class="{online: `${patient.status}` === 'online',away: `${patient.status}` === 'away'}">
                        <div class="avatar-xs align-self-center">
                                <span class="avatar-title rounded-circle bg-light text-body">{{
                                    patient.name.charAt(0)
                                  }}</span>
                        </div>
                        <span class="user-status"></span>
                      </div>
                      <div class="media-body overflow-hidden">
                        <h5 class="text-truncate font-size-14 mb-1">
                          {{ patient.name }}
                        </h5>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </simplebar>
          </div>
        </div>
        <div class="col-lg-6 order-3 order-md-2">
          <div class="row mt-3 mb-1">
            <div class="col-lg-8 col-12">
              <h3 class="text-lg-left text-center mb-3">{{ modeTitle }}</h3>
            </div>
            <div class="col-lg-4 col-12 text-right">
              <ul class="list-inline pedisteps-view-nav mb-0">
                <li class="list-inline-item w-100">
                  <b-dropdown class="w-100" toggle-class="w-100 p-0" right variant="white">
                    <template v-slot:button-content>
                      <div class="d-flex flex-column text-center text-md-right w-100">
                        <h5 class="font-size-13 mb-1 text-truncate">{{ selectedTimeOption }}</h5>
                        <p class="font-size-13 text-muted text-truncate mb-0">
                          <i class="mdi mdi-timetable"></i>
                          {{ $t('pages.pedisteps.timeRange') }}
                        </p>
                      </div>
                    </template>
                    <b-dropdown-item
                        v-for="(option,k) in timeOptions"
                        :key="`timeopt-${k}`"
                        @click="selectTimeRange(option)">
                      {{ option.label }}
                    </b-dropdown-item>
                  </b-dropdown>
                </li>
              </ul>
            </div>
          </div>
          <div class="pedisteps-heatmap" v-if="mode<2" :class="{'disabled': !selectedSession}">
            <!--            sptial temp-->
            <div class="card">
              <div class="card-header">
                <h3 class="text-center font-size-16 m-0">{{ $t('pages.pedisteps.spatialTemporalParameters') }}</h3>
              </div>
              <div class="card-body">
                <div class="row" :class="{'disabled': !spatialTemporalParameters}">
                  <div class="col-lg-4">
                    <h3 class="text-center font-size-14">{{ $t('general.left') }}</h3>
                    <label
                        class="badge badge-dark d-block p-2">{{
                        `${$t('pages.pedisteps.stance')} ${this.spatialTemporalParameters.L.stance}%`
                      }}</label>
                    <label
                        class="badge badge-dark d-block p-2">{{
                        `${$t('pages.pedisteps.swing')} ${this.spatialTemporalParameters.L.swing}%`
                      }}</label>
                  </div>
                  <div class="col-lg-4">
                    <h3 class="text-center font-size-14">{{ $t('pages.pedisteps.doubleSupport') }}</h3>
                    <label
                        class="badge badge-dark d-block p-2">{{
                        `${$t('pages.pedisteps.stance')} ${this.spatialTemporalParameters.doubleSupport}%`
                      }}</label> <label
                      class="badge badge-light d-block p-2">{{
                      `${$t('general.normal')} 20%-40%`
                    }}</label>
                  </div>
                  <div class="col-lg-4">
                    <h3 class="text-center font-size-14">{{ $t('general.right') }}</h3>
                    <label
                        class="badge badge-dark d-block p-2">{{
                        `${$t('pages.pedisteps.stance')} ${this.spatialTemporalParameters.R.stance}%`
                      }}</label>
                    <label
                        class="badge badge-dark d-block p-2">{{
                        `${$t('pages.pedisteps.swing')} ${this.spatialTemporalParameters.R.swing}%`
                      }}</label>
                  </div>
                </div>
              </div>
            </div>
            <!--          gait heatmap-->
            <div class="card">
              <div class="card-header d-flex justify-content-between align-items-center">
                <h3 class="text-center font-size-16 m-0">{{ $t('pages.pedisteps.gaitHeatmap') }}</h3>
                <label v-if="selectedSession&&selectedSession.activity"
                       class="badge badge-success">{{ $t(`pages.pedisteps.${selectedSession.activity}`) }}</label>
              </div>
              <div class="card-body">
                <div class="d-flex">
                  <div v-if="this.pedisol && !!this.pedisol.LState">
                    <label class="badge d-block badge-dark mt-3 mb-0 p-2">{{ this.pedisol.LState }}</label>
                    <svg width="96" height="72" viewBox="0 0 160 120">
                      <!-- Left Foot Line -->
                      <line x1="80" y1="10" x2="110" y2="100" stroke="#000" stroke-width="4"/>
                      <line x1="110" y1="100" x2="154" y2="100" stroke="#000" stroke-width="4"/>
                      <circle cx="80" cy="10" r="4" fill="#000"/> <!-- Starting point circle -->
                      <circle cx="154" cy="100" r="4" fill="#000"/> <!-- Ending point circle -->
                    </svg>
                  </div>
                  <div class="steps-container">
                    <div class="steps-left">
                      <div v-if="switchGroupSensors">
                        <svg width="174" height="459" viewBox="0 0 174 459">
                          <!-- Medial Forefoot -->
                          <g transform="translate(82.000000,120.000000) scale(0.100000,-0.100000)"
                             :fill="heatRgba(groupedSensors.L.medialForefoot).bg" opacity="0.7" stroke="none">
                            <path d="M164 1086 c-28 -7 -76 -23 -108 -35 l-56 -23 0 -514 0 -514 415 0
c374 0 415 2 415 16 0 9 5 81 11 160 38 489 -113 835 -396 909 -70 18 -209 18
-281 1z"/>
                          </g>
                          <!-- Lateral Forefoot -->
                          <g transform="translate(4.000000,120.000000) scale(0.100000,-0.100000)"
                             :fill="heatRgba(groupedSensors.L.lateralForefoot).bg" opacity="0.7" stroke="none">
                            <path d="M656 1005 c-98 -59 -151 -102 -235 -191 -179 -189 -334 -483 -394
-746 l-15 -68 359 0 359 0 0 520 c0 286 -4 520 -8 520 -5 0 -34 -16 -66 -35z"/>
                          </g>
                          <!-- Calcaneus -->
                          <g transform="translate(26.000000,450.000000) scale(0.100000,-0.100000)"
                             :fill="heatRgba(groupedSensors.L.calcaneus).bg" :opacity="0.7" stroke="none">
                            <path d="M82 1274 c-8 -31 -17 -109 -22 -174 -9 -112 -31 -277 -51 -384 -14
-74 -11 -218 7 -304 44 -218 171 -355 369 -397 89 -19 264 -19 334 0 181 48
299 173 352 372 37 138 29 302 -40 748 -11 72 -24 144 -29 160 l-8 30 -450 3
-449 2 -13 -56z"/>
                          </g>
                          <!-- Medial Midfoot -->
                          <g transform="translate(82.000000,310.000000) scale(0.100000,-0.100000)"
                             :fill="heatRgba(groupedSensors.L.medialMidfoot).bg" opacity="0.7" stroke="none">
                            <path d="M0 920 l0 -920 240 0 c195 0 240 3 240 13 0 8 -9 56 -21 108 -26 120
-36 364 -20 480 17 118 75 283 175 502 126 276 167 402 200 613 9 56 16 106
16 113 0 8 -113 11 -415 11 l-415 0 0 -920z"/>
                          </g>
                          <!-- Lateral Midfoot -->
                          <g transform="translate(6.000000,310.000000) scale(0.100000,-0.100000)"
                             :fill="heatRgba(groupedSensors.L.lateralMidfoot).bg" opacity="0.7" stroke="none">
                            <path d="M31 1793 c-53 -276 -35 -613 49 -916 17 -59 53 -166 80 -238 69 -178
80 -233 87 -451 l6 -188 243 0 244 0 0 925 0 925 -349 0 -348 0 -12 -57z"/>
                          </g>
                        </svg>
                      </div>
                      <div v-else>
                        <i :key="`sl-${i}`"
                           :style="{left: `${s.x-10}px`, top: `${s.y}px`, 'box-shadow':`0 0 20px 4px ${heatRgba(valL[i]).sh}`, background: heatRgba(valL[i]).bg }"
                           v-for="(s,i) in pedisol40"></i>
                      </div>
                    </div>
                    <div class="steps-center">
                      <p class="text-center mb-2"><small>{{ $t('general.balance') }}</small></p>
                      <div class="balance-indicator">
                        <div class="balance-line"></div>
                        <div
                            class="balance-circle"
                            :style="{ left: `${balancePosition}%` }"
                        ></div>
                      </div>
                    </div>
                    <div class="steps-right">
                      <div v-if="switchGroupSensors">
                        <svg width="174" height="459" viewBox="0 0 174 459">
                          <!-- Medial Forefoot -->
                          <g transform="translate(92.000000,120.000000) scale(-0.100000,-0.100000)"
                             :fill="heatRgba(groupedSensors.R.medialForefoot).bg" opacity="0.7" stroke="none">
                            <path d="M164 1086 c-28 -7 -76 -23 -108 -35 l-56 -23 0 -514 0 -514 415 0
c374 0 415 2 415 16 0 9 5 81 11 160 38 489 -113 835 -396 909 -70 18 -209 18
-281 1z"/>
                          </g>
                          <!-- Lateral Forefoot -->
                          <g transform="translate(170.000000,120.000000) scale(-0.100000,-0.100000)"
                             :fill="heatRgba(groupedSensors.R.lateralForefoot).bg" opacity="0.7" stroke="none">
                            <path d="M656 1005 c-98 -59 -151 -102 -235 -191 -179 -189 -334 -483 -394
-746 l-15 -68 359 0 359 0 0 520 c0 286 -4 520 -8 520 -5 0 -34 -16 -66 -35z"/>
                          </g>
                          <!-- Calcaneus -->
                          <g transform="translate(148.000000,450.000000) scale(-0.100000,-0.100000)"
                             :fill="heatRgba(groupedSensors.R.calcaneus).bg" :opacity="0.7" stroke="none">
                            <path
                                d="M82 1274 c-8 -31 -17 -109 -22 -174 -9 -112 -31 -277 -51 -384 -14 -74 -11 -218 7 -304 44 -218 171 -355 369 -397 89 -19 264 -19 334 0 181 48 299 173 352 372 37 138 29 302 -40 748 -11 72 -24 144 -29 160 l-8 30 -450 3 -449 2 -13 -56z"/>
                          </g>
                          <!-- Medial Midfoot -->
                          <g transform="translate(92.000000,310.000000) scale(-0.100000,-0.100000)"
                             :fill="heatRgba(groupedSensors.R.medialMidfoot).bg" opacity="0.7" stroke="none">
                            <path d="M0 920 l0 -920 240 0 c195 0 240 3 240 13 0 8 -9 56 -21 108 -26 120
-36 364 -20 480 17 118 75 283 175 502 126 276 167 402 200 613 9 56 16 106
16 113 0 8 -113 11 -415 11 l-415 0 0 -920z"/>
                          </g>
                          <!-- Lateral Midfoot -->
                          <g transform="translate(170.000000,310.000000) scale(-0.100000,-0.100000)"
                             :fill="heatRgba(groupedSensors.R.lateralMidfoot).bg" opacity="0.7" stroke="none">
                            <path d="M31 1793 c-53 -276 -35 -613 49 -916 17 -59 53 -166 80 -238 69 -178
80 -233 87 -451 l6 -188 243 0 244 0 0 925 0 925 -349 0 -348 0 -12 -57z"/>
                          </g>
                        </svg>
                      </div>
                      <div v-else>
                        <i :key="`sl-${i}`"
                           :style="{right: `${s.x-10}px`, top: `${s.y}px`, 'box-shadow':`0 0 20px 4px ${heatRgba(valR[i]).sh}`, background: heatRgba(valR[i]).bg }"
                           v-for="(s,i) in pedisol40"></i>
                      </div>
                    </div>
                  </div>
                  <div v-if="this.pedisol && !!this.pedisol.RState">
                    <label class="badge d-block badge-dark mt-3 mb-0 p-2">{{ this.pedisol.RState }}</label>
                    <svg width="96" height="72" viewBox="0 0 160 120">
                      <!-- Right Foot Line (mirrored) -->
                      <line x1="80" y1="10" x2="50" y2="100" stroke="#000" stroke-width="4"/>
                      <line x1="50" y1="100" x2="6" y2="100" stroke="#000" stroke-width="4"/>
                      <circle cx="80" cy="10" r="4" fill="#000"/> <!-- Starting point circle -->
                      <circle cx="6" cy="100" r="4" fill="#000"/> <!-- Ending point circle -->
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <!-- Timeline Slider -->
            <div class="card">
              <div class="card-body">
                <div class="timeline-container mb-5">
                  <vue-slider
                      v-model="selectedRange"
                      :min="0"
                      :max="Math.max(0, pedisolSession.length-1)"
                      :tooltip="'always'"
                      :tooltip-formatter="formatTooltip"
                      :dot-options="{ tooltip: 'always' }"
                      :tooltip-placement="['bottom', 'bottom']"
                      :marks="generateMarks"
                      range
                      class="w-100">
                    <template v-slot:label="{ label, active }">
                      <div :class="['vue-slider-mark-label', 'custom-label', { active }]">{{ label }}</div>
                    </template>
                  </vue-slider>
                </div>
                <!-- Action Buttons -->
                <div class="row">
                  <div class="col-lg-4 mb-3">
                    <b-button class="form-control" variant="primary" :disabled="!buttonStates.analyze"
                              @click="analyzeGait">
                      {{ $t('general.analyze') }} <i class="ri ri-search-2-line"></i>
                    </b-button>
                  </div>
                  <div class="col-lg-4 mb-3">
                    <b-button variant="success"
                              :disabled="selectedRange[1]-selectedRange[0]> 100 || !buttonStates.insights"
                              @click="generateInsights" class="form-control">
                      {{ $t('pages.pedisteps.insights') }}&nbsp;<i class="ri ri-openai-fill"></i>
                    </b-button>
                  </div>
                  <div class="col-lg-4 mb-3">
                    <b-button class="form-control" variant="warning" @click="tagSegment">
                      {{ $t('general.tag') }} <i class="ri ri-price-tag-line"></i>
                    </b-button>
                  </div>
                  <div class="col-lg-4 mb-3">
                    <b-button variant="danger" disabled @click="confirmDeleteSegment" class="form-control">
                      {{ $t('general.delete') }} <i class="ri ri-delete-bin-2-line"></i>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pedisteps-events custom-scrollbar" v-if="mode === modes.EVENTS">
            <ul v-if="mappedEvents.length" class="list-group">
              <li v-for="(event,k) in mappedEvents" :key="`ev-${k}`"
                  class="list-group-item d-flex justify-content-between align-items-center">
                <span>
                  <span class="badge mr-2" :class="event.labelClass">{{ event.labelText }}</span>
                  <span class="event-description" v-html="event.description"></span>
                </span>
                <span class="event-time">{{ event.timestamp }}</span>
              </li>
            </ul>
            <p v-else class="text-lg-left text-center text-muted">No events found for this user.</p>
          </div>
          <div class="pedisteps-insights" v-if="mode === modes.INSIGHTS">
            <div v-if="insights.length > 0">
              <div class="card">
                <div class="card-header">
                  <p class="m-0">{{ $t('pages.pedisteps.noteInsights') }}</p>
                </div>
              </div>
              <ul class="list-unstyled">
                <li v-for="ins in insights" :key="ins.id" class="mb-3 card">
                  <div class="d-flex align-items-start p-2">
                    <b-form-checkbox v-model="selectedInsights" :value="ins.id"></b-form-checkbox>
                    <div>
                      <div v-html="ins.insight" class="mb-2"></div>
                      <div class="d-flex align-items-center gap-2">
                        <b-badge class="px-2 py-1 my-1 mr-1" :variant="ins.isAi ? 'primary' : 'secondary'">
                          {{ ins.isAi ? 'AI Generated' : 'Human Generated' }}
                        </b-badge>
                        <b-badge v-if="!!ins.seen" class="px-2 py-1 my-1 mr-1" :variant="'success'">
                          {{ `${$t('general.seen')}: ${formatDate(ins.seen)}` }}
                        </b-badge>
                        <b-badge class="px-2 py-1 my-1 mr-1" variant="info">{{ $t('general.added') }}: {{
                            formatDate(ins.t)
                          }}
                        </b-badge>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="d-flex justify-content-between my-3">
                <b-button variant="danger" @click="deleteSelectedInsights">Delete Selected</b-button>
                <b-button variant="success" @click="saveSelectedInsights" disabled>Add Insight</b-button>
              </div>
            </div>
            <div v-else><p>No insights available to display.</p></div>
          </div>
        </div>
        <div class="col-lg-3 order-2 order-md-3">
          <div class="pedisteps-tabs">
            <b-tabs content-class="py-3 border-left" tab-class="p-1" justified v-model="mode">
              <b-tab title="Tab Realtime" title-link-class="p-1">
                <template v-slot:title>
                  <i class="ri-wifi-line font-size-20"></i>
                  <span class="mt-2 d-none d-sm-block">{{ $t('pages.pedisteps.realtime') }}</span>
                </template>
                <b-card-text>
                </b-card-text>
              </b-tab>
              <b-tab title="Tab Gait" active title-link-class="p-1">
                <template v-slot:title>
                  <i class="ri-walk-line font-size-20"></i> {{ sessions.length }}
                  <span class="mt-2 d-none d-sm-block">{{ $t('pages.pedisteps.gait') }}</span>
                </template>
                <b-card-body>
                  <h5 class="font-size-14 mb-3">{{ $t('general.settings') }}</h5>
                  <div class="row">
                    <div class="col-9">
                      <label>{{ $t(`pages.pedisteps.saveAnalysis`) }}</label>
                    </div>
                    <div class="col-3 text-right">
                      <b-form-checkbox v-model="switchUpdatePedisol" name="check-button" switch/>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-9">
                      <label>{{ $t(`pages.pedisteps.enableGroupSensors`) }}</label>
                    </div>
                    <div class="col-3 text-right">
                      <b-form-checkbox v-model="switchGroupSensors" name="check-button" switch/>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-9">
                      <label>{{ $t(`pages.pedisteps.deleteDuplicates`) }}</label>
                    </div>
                    <div class="col-3 text-right">
                      <b-form-checkbox v-model="switchDelete" name="check-button" switch/>
                    </div>
                  </div>
                  <hr>
                  <h5 class="font-size-14 mb-3">{{ $t('pages.pedisteps.sessions') }}</h5>
                  <input v-model="tagSearch" class="form-control  mb-3" :placeholder="$t('general.search')"/>
                  <simplebar id="scrollSessions">
                    <ul class="list-unstyled session-list">
                      <li v-for="(s, k) of filteredSessions" :key="`session-${k}`"
                          :class="{'active': !!selectedSession && selectedSession.sessionId===s.sessionId}">
                        <a href="javascript: void(0);" @click="selectedSession = s;"
                           :title="`Session: ${s.sessionId}, Timestamp: ${s.T}`">
                          <div class="media">
                            {{ formatTimestamp(s.T) }} for&nbsp;<span v-html="formatDuration(s.Duration)"></span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </simplebar>
                </b-card-body>
              </b-tab>
              <b-tab title="Tab Insights" title-link-class="p-1">
                <template v-slot:title>
                  <i :class="['font-size-20', {'ri-openai-line': !lightbulbActive, 'ri-openai-fill': lightbulbActive}]"></i>
                  {{ insights.length }}
                  <span class="mt-2 d-none d-sm-block">{{ $t('pages.pedisteps.insights') }}</span>
                </template>
                <b-card-body>
                  <h5 class="font-size-14 mb-3">{{ $t('pages.pedisteps.sessions') }}</h5>
                  <input v-model="tagSearch" class="form-control  mb-3" :placeholder="$t('general.search')"/>
                  <simplebar id="scrollSessions">
                    <ul class="list-unstyled session-list">
                      <li v-for="(s, k) of filteredSessions" :key="`session-${k}`"
                          :class="{'active': !!selectedSession && selectedSession.sessionId===s.sessionId}">
                        <a href="javascript: void(0);" @click="selectedSession = s;"
                           :title="`Session: ${s.sessionId}, Timestamp: ${s.T}`">
                          <div class="media">
                            {{ formatTimestamp(s.T) }} for&nbsp;<span v-html="formatDuration(s.Duration)"></span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </simplebar>
                </b-card-body>
              </b-tab>
              <b-tab title="Tab Events" title-link-class="p-1">
                <template v-slot:title>
                  <i class="ri-list-unordered font-size-20"></i> {{ events.length }}
                  <span class="mt-2 d-none d-sm-block">{{ $t('pages.pedisteps.eventLog') }}</span>
                </template>
                <b-card-body>
                  <h5 class="font-size-14 mb-3">{{ $t('general.settings') }}</h5>
                  <div class="row">
                    <div class="col-6">
                      <label>{{ $t('pages.pedisteps.eventTypes') }}</label>
                    </div>
                    <div class="col-6 text-right">
                      <b-button
                          class="badge border-0"
                          :variant="eventType === 'system' ? 'warning' : eventType === 'medical' ? 'danger' : eventType === 'user' ?  'primary': ''"
                          @click="toggleEventType">
                        {{ $t(`general.${eventType}`) }}
                      </b-button>
                    </div>
                  </div>
                  <hr>
                </b-card-body>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="tag-modal" size="sm" title="Add Tag">
      <b-form-group label="Tag">
        <b-form-input v-model="tagText" placeholder="Enter tag" :state="isValidTag"/>
        <b-form-invalid-feedback>Alphanumeric only, no spaces or special characters.</b-form-invalid-feedback>
      </b-form-group>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('tag-modal')">Cancel</b-button>
        <b-button variant="primary" :disabled="!isValidTag" @click="saveTag">Save</b-button>
      </template>
    </b-modal>
    <b-modal
        id="insightsModal"
        v-model="insightsModal"
        title="Insights"
        hide-footer
        size="lg">
      <div v-if="insights.length > 0">
        <ul class="list-unstyled">
          <li v-for="(insight,index) in insights" :key="index" class="mb-3 d-flex align-items-center">
            <b-form-checkbox
                v-model="selectedInsights"
                :value="insight"
                class="mr-3"
            ></b-form-checkbox>
            <div v-html="insight.text"></div>
          </li>
        </ul>
        <b-button variant="success" class="mt-3" @click="saveSelectedInsights">
          Save Selected Insights
        </b-button>
        <b-button variant="danger" class="mt-3 ml-2" @click="discardInsights">
          Discard
        </b-button>
      </div>
      <div v-else>
        <p>No insights available to display.</p>
      </div>
    </b-modal>
  </Layout>
</template>
<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import {BButton} from 'bootstrap-vue'
import simplebar from 'simplebar-vue'
// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app'
// eslint-disable-next-line no-unused-vars
import {
  addDoc,
  collection,
  getDoc,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
  serverTimestamp
} from 'firebase/firestore'
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import {pedisol30, pedisol40, regions40, stancePhases, swingPhases, thresholds} from './data'
import Background from '@/assets/images/shoe-prints.svg'
import {monitorMethods, userMethods} from '@/state/helpers'
import {getApi} from '@/api'

const firebaseConfig = {
  apiKey: 'AIzaSyDY20ZKArG6BDHunz_Hr9HSbIOikxemZ34',
  authDomain: 'vrsteps-3866d.firebaseapp.com',
  databaseURL: 'https://vrsteps-3866d.firebaseio.com',
  projectId: 'vrsteps-3866d',
  storageBucket: 'vrsteps-3866d.appspot.com',
  messagingSenderId: '551929963690',
  appId: '1:551929963690:web:7190bead804cb7a4505cd3',
  measurementId: 'G-KZE3TMDG9R'
}
// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firestore
const db = getFirestore(app)
/**
 * Pedisteps Component
 */

export default {
  page: {
    title: 'Pedisteps Gait',
    meta: [{name: 'description'}],
  },

  components: {
    Layout,
    PageHeader,
    simplebar,
    VueSlider,
    BButton
  },

  data() {
    return {
      buttonStates: {
        analyze: true,
        insights: true,
      },
      events: [],
      insights: [],
      insightsModal: false, // Toggle modal visibility
      items: [
        {
          text: 'VRsteps',
          href: '/',
        },
        {
          text: 'Pedisteps',
          active: true,
        },
      ],
      limits: {
        sessionData: 2000,
        events: 1000,
        sessions: 250,
      },
      sessions: [],
      eventType: 'all',
      eventTypes: ['all', 'medical', 'system', 'user'],
      mode: 0,
      modes: {REALTIME: 0, GAIT: 1, INSIGHTS: 2, EVENTS: 3},
      pedisolSize: 40,
      realtimeBackground: Background,
      pedisol: {
        L: [],
        R: [],
      },
      pedisol30: pedisol30,
      pedisol40: pedisol40,
      pedisolSession: [],
      submitted: false,
      toasterSettings: {
        solid: true,
        toaster: 'b-toaster-bottom-right',
        autoHideDelay: 3000,
        noCloseButton: true
      },
      title: 'pages.pedisteps.title',
      timeOptions: [
        {label: this.$t('time.last24h'), value: this.calculateTimestamp(24)},
        {label: this.$t('time.last3d'), value: this.calculateTimestamp(24 * 3)},
        {label: this.$t('time.last7d'), value: this.calculateTimestamp(24 * 7)},
        {label: this.$t('time.last30d'), value: this.calculateTimestamp(24 * 30)},
        {label: this.$t('time.last90d'), value: this.calculateTimestamp(24 * 90)},
        {label: this.$t('time.pastYear'), value: this.calculateTimestamp(24 * 360)}
      ],
      selectedPatient: {
        id: null,
        name: '',
        image: '',
        activation_token: '',
        lastSession: null,
        lastSessionDate: 'N/A',
        isActive: false,
        totalUsageTime: 'N/A',
        totalEvents: '0'
      },
      selectedInsights: [], // Store selected insights for saving
      selectedTimeOption: '',
      selectedTimestamp: null,
      selectedSession: null,
      selectedRange: [0, 0], // Start and End points
      previousRange: [0, 0], // Track the previous range
      switchGroupSensors: false,
      switchDelete: false,
      switchUpdatePedisol: false,
      tagText: '',
      tagSearch: '',
      lightbulbActive: false,
    }
  },

  methods: {
    ...userMethods,
    ...monitorMethods,
    ...getApi(),

    selectPatient(patient) {
      this.reset();
      this.selectedPatient = patient
      this.loadEvents();
      this.loadSessions();
      this.loadInsights();
    },

    // Handle option selection
    selectTimeRange(option) {
      this.selectedTimeOption = option.label;
      this.selectedTimestamp = option.value; // Store the selected timestamp
      this.reset();
      this.loadEvents();
      this.loadSessions();
    },

    // Calculate the timestamp for X hours ago
    calculateTimestamp(hoursAgo) {
      const now = new Date();
      const timestamp = new Date(now.getTime() - hoursAgo * 60 * 60 * 1000);
      return Math.floor(timestamp.getTime()); // Firebase expects timestamps in milliseconds
    },

    heatRgba(pressure) {

      pressure = pressure / this.scalingFactor;

      if (pressure < 0 || pressure > 255) return {bg: 'rgba(255, 255, 255, 0.9)', sh: 'rgba(255, 255, 255, 0.9)'}; // Out of bounds, return white

      let r = 255;
      let g = 255;
      let b = 255;

      if (pressure <= 50) {
        // From white to blue
        const factor = pressure / 50;
        r = 255 * (1 - factor);
        g = 255 * (1 - factor);
        b = 255;
      } else if (pressure <= 100) {
        // From blue to green
        const factor = (pressure - 50) / 50;
        r = 0;
        g = 255 * factor;
        b = 255 - 255 * factor;
      } else if (pressure <= 150) {
        // From green to yellow
        const factor = (pressure - 100) / 50;
        r = 255 * factor;
        g = 255;
        b = 0;
      } else if (pressure <= 200) {
        // From yellow to orange
        const factor = (pressure - 150) / 50;
        r = 255;
        g = 255 - 90 * factor;
        b = 0;
      } else {
        // From orange to dark red
        const factor = (pressure - 200) / 55;
        r = 255 - 116 * factor;
        g = 165 - 165 * factor;
        b = 0;
      }

      return {bg: `rgba(${r}, ${g}, ${b}, 0.5)`, sh: `rgba(${r}, ${g}, ${b}, 1)`};
    },

    getGender(gender) {
      if (gender === 1) return 'Male';
      else if (gender === 2) return 'Female';
      else return 'Other';
    },

    formatTooltip(value) {
      const item = this.pedisolSession[value];
      const startTime = this.pedisolSession[0]?.T; // Get the start timestamp


      if (item && startTime) {

        const timeElapsed = Math.floor((item.T - startTime) / 1000); // Calculate time elapsed in seconds
        const minutes = Math.floor(timeElapsed / 60);
        const seconds = timeElapsed % 60;

        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      }

      return '00:00'; // Default value if item or startTime is undefined
    },

    async tagSegment() {
      this.tagText = ''; // Reset the tag input
      this.$bvModal.show('tag-modal'); // Open the modal
    },

    async saveTag() {
      if (!this.isValidTag) {
        // Show an error toast if the tag is invalid
        this.$bvToast.toast('Tag is invalid. Please use alphanumeric characters only.', {
          variant: 'danger',
          ...this.toasterSettings
        });
        return;
      }

      const start = this.selectedRange[0];
      const end = this.selectedRange[1];
      const tag = this.tagText;

      // Update each pedisol document in the range
      for (let i = start; i <= end; i++) {
        const item = this.pedisolSession[i];
        if (this.switchUpdatePedisol && item?.id) {
          this.updatePedisol(item.id, {Tag: tag}).then();
        }
      }

      // Update session with tag in array
      if (this.selectedSession.sessionId) {
        const sessionRef = doc(db, 'sessions', this.selectedSession.sessionId);
        const sessionDoc = await getDoc(sessionRef);
        const currentTags = sessionDoc.exists() && Array.isArray(sessionDoc.data().Tags)
            ? sessionDoc.data().Tags
            : [];
        if (!currentTags.includes(tag)) {
          currentTags.push(tag);
          await this.updateSession(sessionRef, {Tags: currentTags});
          // Update selectedSession locally to reflect the change
          if (this.selectedSession) {
            this.selectedSession.Tags = currentTags;
          }
        }
      }

      // Show a success toast notification
      this.$bvToast.toast('Segment tagged successfully.', {
        variant: 'success',
        ...this.toasterSettings
      });

      // Close the modal if successful
      this.$bvModal.hide('tag-modal'); // Ensure 'tagModal' matches the modal ID
    },

    async analyzeGait() {
      // Disable the button
      this.buttonStates.analyze = false;

      for (let i = this.selectedRange[0]; i <= this.selectedRange[1]; i++) {
        const item = this.pedisolSession[i];
        const {LState, RState} = this.determineGaitPhase(item);

        // Prepare object with keys and values to update
        const updateData = {};
        if (!item.LState) updateData.LState = LState;
        if (!item.RState) updateData.RState = RState;

        // Update locally
        item.LState = LState;
        item.RState = RState;

        // Optional: Save to Firestore if values don't exist
        if (this.switchUpdatePedisol) {
          await this.updatePedisol(item.id, {LState, RState});
        }
      }

      // Re-enable the button when analysis is complete
      this.buttonStates.analyze = true;
    },

    determineGaitPhase(pedisol) {
      // Check if either foot data is empty, indicating N/A
      // if (!pedisol || !pedisol.L || pedisol.L.length === 0 || !pedisol.R || pedisol.R.length === 0) {
      //   return {LState: "N/A", RState: "N/A"};
      // }

      // Use groupSensors to get average pressure values for each region
      const groupedSensors = this.groupSensors(pedisol);

      // Helper function to determine stance phase for a given foot
      const getStancePhase = (foot) => {
        const {calcaneus, medialForefoot, lateralForefoot, medialMidfoot, lateralMidfoot} = foot;
        //todo use scale
        if (calcaneus > thresholds.calcaneus && medialForefoot < thresholds.stance && lateralForefoot < thresholds.stance) {
          return "Initial Contact";
        }
        if (calcaneus > thresholds.midfoot && medialMidfoot > thresholds.stance && lateralMidfoot > thresholds.stance) {
          return "Loading Response";
        }
        if (medialMidfoot > thresholds.midfoot && lateralMidfoot > thresholds.midfoot) {
          return "Mid-Stance";
        }
        if (medialForefoot > thresholds.forefoot && lateralForefoot > thresholds.forefoot && calcaneus < thresholds.stance) {
          return "Terminal Stance";
        }
        if (medialForefoot > thresholds.calcaneus && lateralForefoot > thresholds.calcaneus) {
          return "Pre-Swing";
        }
        return "Swing Phase";
      };

      // Determine the stance phase for both feet
      let LStancePhase = getStancePhase(groupedSensors.L);
      let RStancePhase = getStancePhase(groupedSensors.R);

      // Cross-check swing sub-phases for each foot based on opposite foot's stance
      const getSwingSubPhase = (footStance, oppositeStance) => {
        if (footStance === "Swing Phase") {
          if (["Initial Contact", "Loading Response"].includes(oppositeStance)) return "Initial Swing";
          if (oppositeStance === "Mid-Stance") return "Mid-Swing";
          if (["Terminal Stance", "Pre-Swing"].includes(oppositeStance)) return "Late Swing";
        }
        return footStance; // If not in swing phase, return the detected stance phase
      };

      // Determine swing sub-phases using cross-checking logic
      const LState = getSwingSubPhase(LStancePhase, RStancePhase);
      const RState = getSwingSubPhase(RStancePhase, LStancePhase);

      // Check for double support (both feet in stance phase)
      if (!LStancePhase.includes("Swing Phase") && !RStancePhase.includes("Swing Phase")) {
        return {LState: "Double Support", RState: "Double Support"};
      }

      return {LState, RState};
    },

    async confirmDeleteSegment() {
      if (confirm('Are you sure you want to delete this segment?')) {
        const start = this.selectedRange[0];
        const end = this.selectedRange[1];
        // Logic to delete data in Firestore for the selected range
        await this.deleteItemsFromFirestore(start, end);
        alert('Segment deleted successfully.');
      }
    },

    // eslint-disable-next-line no-unused-vars
    async deleteItemsFromFirestore(start, end) {
      // Logic to delete items in Firestore based on start and end range
    },

    async updateSession(sessionDocRef, data) {
      try {
        await updateDoc(sessionDocRef, data);
      } catch (error) {
        console.error(`Error updating session:`, error);
      }
    },

    async deleteSessionByRef(sessionDocRef) {
      try {
        await deleteDoc(sessionDocRef);
        console.log(`Deleted session with ref: ${sessionDocRef.id}`);
      } catch (error) {
        console.error(`Error deleting session:`, error);
      }
    },

    async getLastPedisolItem(sessionId) {
      const q = query(
          collection(db, 'pedisol'),
          where('Session', '==', sessionId),
          orderBy('T', 'desc'),
          limit(1)
      );

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        return querySnapshot.docs[0].data();
      }
      return null;
    },

    async generateInsights() {
      this.buttonStates.insights = false;

      this.$bvToast.toast('Insights are being generated, please stand by.', {
        variant: 'warning',
        ...this.toasterSettings
      });

      const myHeaders = new Headers();
      myHeaders.append("Action", "insights");
      myHeaders.append("Token", "bcaa95cc8e8800c0333b1de5c7b989a32b0aef71");
      myHeaders.append("Content-Type", "application/json");

      const patient = {
        gender: this.getGender(this.selectedPatient.gender), // Replace with actual gender
        age: this.selectedPatient.age || 35 // Replace with actual age
      };

      // Get the base timestamp from the first item in the selected range
      const baseTimestamp = this.pedisolSession[this.selectedRange[0]]?.T || 0;

      const gaitData = [];
      for (let i = this.selectedRange[0]; i <= this.selectedRange[1]; i++) {
        const item = this.pedisolSession[i];
        const timestampDiff = item?.T ? item.T - baseTimestamp : 0; // Calculate time difference in ms
        const groupedData = this.groupSensors(item);

        gaitData.push({
          T: timestampDiff, // Use actual ms difference
          L: groupedData.L,
          R: groupedData.R,
        });
      }

      const requestBody = {
        user_id: this.selectedPatient.id,
        patient,
        insoles: {
          sensors: this.pedisolSize,
          duration: (this.selectedRange[1] - this.selectedRange[0]) * (baseTimestamp ? 1000 : 1) // Adjust duration to ms
        },
        gait: gaitData
      };

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow"
      };

      try {
        const response = await fetch("https://api.vrsteps.io/", requestOptions);
        const result = await response.json();

        if (result.status && result.insights) {
          // Parse and format the insights text
          this.insights = result.insights.split("\n\n").map((insight) => ({
            text: insight.trim().replace(/\*\*(.*?)\*\*/g, '<b>$1</b>') // Replace **TEXT** with <b>TEXT</b>
          }));
          this.insightsModal = true; // Show the modal
          this.buttonStates.insights = true;
        } else {
          console.error("Unexpected response format:", result);
        }
      } catch (error) {
        console.error("Error fetching insights:", error);
      }
    },

    async updatePedisol(docId, gaitData) {
      try {
        if (!docId) {
          console.log("Invalid document ID: docId is undefined or null");
          return;
        }
        // Create the document reference using the collection name and document ID
        const pedisolDocRef = doc(db, "pedisol", docId);
        // Update Firestore with the provided gaitData
        await updateDoc(pedisolDocRef, gaitData);
      } catch (error) {
        console.error(`Error updatePedisol:`, error);
      }
    },

    saveSelectedInsights() {
      if (this.selectedInsights.length === 0) {
        this.$bvToast.toast("No insights selected to save.", {
          variant: "warning",
          ...this.toasterSettings
        });
        return;
      }

      this.selectedInsights.forEach(async (insight) => {
        const insightData = {
          insight: insight.text,
          isAi: true,
          selectedRange: this.selectedRange,
          session: this.selectedSession.sessionId,
          t: Date.now(),
          token: this.selectedPatient.activation_token
        };
        await this.saveInsight(insightData);
      });

      this.$bvToast.toast("Selected insights saved successfully.", {
        variant: "success",
        ...this.toasterSettings
      });

      this.insightsModal = false; // Close the modal
      this.selectedInsights = []; // Clear selected insights
    },

    async deleteSelectedInsights() {
      if (this.selectedInsights.length === 0) {
        this.$bvToast.toast("No insights selected for deletion.", {
          variant: "warning",
          ...this.toasterSettings
        });
        return;
      }

      try {
        const promises = this.selectedInsights.map(async (insightId) => {
          const insightRef = doc(db, "insights", insightId);
          await deleteDoc(insightRef);
        });

        await Promise.all(promises);

        // Remove deleted insights from local state
        this.insights = this.insights.filter((ins) => !this.selectedInsights.includes(ins.id));
        this.selectedInsights = []; // Clear selected insights

        this.$bvToast.toast("Selected insights deleted successfully.", {
          variant: "success",
          ...this.toasterSettings
        });
      } catch (error) {
        console.error("Error deleting insights:", error);
        this.$bvToast.toast("Failed to delete insights. Please try again.", {
          variant: "danger",
          ...this.toasterSettings
        });
      }
    },

    setLastSession() {
      if (this.unsubscribeLastSession) {
        this.unsubscribeLastSession();
      }

      //do it for self
      const q = query(
          collection(db, 'sessions'),
          orderBy('T', 'desc'),
          where('T', '>=', this.selectedTimestamp),
          where('Token', '==', this.$store.state.user.activation_token),
          limit(1)
      );

      this.unsubscribeLastSession = onSnapshot(q, (querySnapshot) => {
        if (!querySnapshot.empty) {
          const latestDoc = querySnapshot.docs[0].data();
          this.setUserLastSession({lastSession: latestDoc});
          if (this.unsubscribeLastSession) {
            this.unsubscribeLastSession();
          }
        } else {
          this.setUserLastSession({lastSession: null});
        }
      }, (error) => {
        console.error(`Error fetching data for patient ${this.$store.state.user.name}: `, error);
      });

      //do it for patients
      this.$store.state.monitor.patients.forEach(patient => {
        const token = patient.activation_token;
        const q = query(
            collection(db, 'sessions'),
            orderBy('T', 'desc'),
            where('T', '>=', this.selectedTimestamp),
            where('Token', '==', token),
            limit(1)
        );

        this.unsubscribe = onSnapshot(q, (querySnapshot) => {
          if (!querySnapshot.empty) {
            const latestDoc = querySnapshot.docs[0].data();
            this.setPatientLastSession({patientId: patient.id, lastSession: latestDoc});
          } else {
            this.setPatientLastSession({patientId: patient.id, lastSession: null});
          }
        }, (error) => {
          console.error(`Error fetching data for patient ${patient.name}: `, error);
        });
      });
    },

    loadSessions() {
      // Generate a unique request ID for each loadSessions call
      const currentRequestId = Symbol("requestId");
      this.activeRequestId = currentRequestId;

      // Unsubscribe from previous snapshot listener if it exists
      if (this.unsubscribeSessions) {
        this.unsubscribeSessions();
      }

      // Clear and reinitialize sessions
      this.sessions = [];

      const q = query(
          collection(db, 'sessions'),
          where('T', '>=', this.selectedTimestamp),
          where('Token', '==', this.selectedPatient.activation_token),
          orderBy('T', 'desc'),
          limit(this.limits.sessions)
      );

      this.unsubscribeSessions = onSnapshot(q, async (querySnapshot) => {

            for (const change of querySnapshot.docChanges()) {

              if (this.activeRequestId !== currentRequestId) {
                return;
              }

              const s = change.doc.data();
              const changeType = change.type;
              let seenlist = {};

              // Use session ID as a unique identifier to prevent duplicates
              if (changeType === 'added') {

                if (!s.Duration) {
                  const sessionId = s.Session ? s.Session : change.doc.id;
                  this.getLastPedisolItem(sessionId).then(lastPedisolT => {
                    if (lastPedisolT) {
                      const duration = lastPedisolT.T - s.T;
                      s.Duration = duration;
                      this.updateSession(change.doc.ref, {Duration: duration}).then();
                    } else {
                      //set Expire for cleanup session without any pedisol
                      this.updateSession(change.doc.ref, {Expire: serverTimestamp()}).then();
                    }
                  });
                }
                // Add the session if it’s unique and not removed
                if (!this.switchDelete || !seenlist[change.doc.id]) {
                  this.sessions.push({...s, sessionId: (s.Session ? s.Session : change.doc.id)}); // Optionally include ID for better tracking
                  seenlist[change.doc.id] = true;
                }
              }
            }
          },
          (error) => {
            console.error('Error listening to collection changes: ', error);
          }
      );
    },

    loadRealtime() {
      if (this.selectedPatient.lastSession === null || !this.selectedPatient.lastSession.Session) return;
      if (this.unsubscribeGait) {
        this.unsubscribeGait();
      }

      // Set query to fetch the latest value from the Firestore
      const q = query(
          collection(db, 'pedisol'),
          orderBy('T', 'desc'), // Order by timestamp descending
          where('Session', '==', this.selectedPatient.lastSession.Session),
          limit(1) // Limit the result to the latest document
      );

      // Real-time listener for new additions or updates in Firestore
      this.unsubscribeGait = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const docData = change.doc.data();
          const changeType = change.type;

          if (typeof docData !== 'undefined' && (changeType === 'added' || changeType === 'modified')) {
            // Set the latest left and right foot data
            this.pedisol = docData;
          }
        });
      }, (error) => {
        console.error('Error listening to collection changes: ', error);
      });
    },

    loadGait() {
      //reset
      if (this.unsubscribeGait) {
        console.log("unsubscribeGait");
        this.unsubscribeGait();
      }

      this.pedisolSession.splice(0);

      const q = query(collection(db, 'pedisol'),
          orderBy('T', 'asc'), // Make sure documents are ordered by createdAt
          where('T', '>=', this.selectedTimestamp),
          where('Session', '==', this.selectedSession.sessionId),
          limit(this.limits.sessionData)
      )

      this.unsubscribeGait = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const docData = change.doc.data()
          const changeType = change.type

          if (changeType === 'added') {
            // Add the document ID to the data
            docData.id = change.doc.id;
            this.pedisolSession.push(docData)
          }
        })

      }, (error) => {
        console.error('Error listening to collection changes: ', error)
      })
    },

    loadEvents() {
      this.events.splice(0);

      if (this.unsubscribeEvents) {
        this.unsubscribeEvents();
      }

      const q = query(
          collection(db, 'events'),
          where('T', '>=', this.selectedTimestamp),
          where('Token', '==', this.selectedPatient.activation_token),
          orderBy('T', 'desc'),
          limit(this.limits.events)
      );

      this.unsubscribeEvents = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const e = change.doc.data();
          const changeType = change.type;

          if (changeType === 'added') {
            this.events.push(e);
          }
        });
      }, (error) => {
        console.error('Error listening to collection changes: ', error);
      });
    },

    async loadInsights() {
      try {
        // Query Firestore for insights ordered by T descending and filtered by Token
        const q = query(
            collection(db, "insights"),
            where("token", "==", this.selectedPatient.activation_token),
            orderBy("t", "desc")
        );

        const querySnapshot = await getDocs(q);

        // Extract and store insights
        this.insights = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id, // Document ID
            ...doc.data() // Document data
          };
        });
      } catch (error) {
        console.error("Error loading insights:", error);
        this.$bvToast.toast("Failed to load insights. Please try again.", {
          variant: "danger",
          ...this.toasterSettings
        });
      }
    },

    groupSensors(pedisol) {
      if (!pedisol || !pedisol.L || !pedisol.R) {
        console.error("Invalid pedisol data:", pedisol);
        return {L: {}, R: {}}; // Return empty object if data is invalid
      }

      // Helper function to calculate the average of an array
      const average = (arr) => arr.length > 0 ? arr.reduce((sum, val) => sum + val, 0) / arr.length : 0;

      // Function to group sensor values by regions based on indices in `regions40`
      const groupByRegion = (footData) => {
        const grouped = {
          medialForefoot: [],
          lateralForefoot: [],
          calcaneus: [],
          medialMidfoot: [],
          lateralMidfoot: []
        };

        // Iterate over each region in `regions40` and populate grouped object
        for (const region in regions40) {
          regions40[region].forEach(index => {
            if (footData && footData[index] !== undefined) {
              grouped[region].push(footData[index]);
            }
          });
        }

        // Calculate averages for each region
        return {
          medialForefoot: average(grouped.medialForefoot),
          lateralForefoot: average(grouped.lateralForefoot),
          calcaneus: average(grouped.calcaneus),
          medialMidfoot: average(grouped.medialMidfoot),
          lateralMidfoot: average(grouped.lateralMidfoot)
        };
      };

      // Calculate averages for both feet using the grouped regions
      const leftFootAverages = groupByRegion(pedisol.L);
      const rightFootAverages = groupByRegion(pedisol.R);

      return {
        L: leftFootAverages,
        R: rightFootAverages
      };
    },

    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      const currentYear = new Date().getFullYear();
      const eventYear = date.getFullYear();

      const day = date.getDate().toString().padStart(2, '0');
      const month = date.toLocaleString('en-US', {month: 'short'}); // "Jun"
      const time = date.toTimeString().split(' ')[0]; // "HH:MM:SS"

      // If it's the current year, don't display the year
      if (eventYear === currentYear) {
        return `${day} ${month}, ${time}`;
      } else {
        return `${day} ${month} ${eventYear}, ${time}`;
      }
    },

    formatDate(timestamp) {
      const date = new Date(timestamp);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },

    formatDuration(duration) {
      if (!duration) return "N/A";

      const seconds = Math.floor((duration / 1000) % 60);
      const minutes = Math.floor((duration / (1000 * 60)) % 60);
      const hours = Math.floor(duration / (1000 * 60 * 60));

      const hoursStr = hours > 0 ? `${hours}h ` : "";
      const minutesStr = minutes > 0 ? `${minutes}m ` : "";
      const secondsStr = seconds > 0 ? `${seconds}s` : "";

      const totalMinutes = hours * 60 + minutes;
      let className = "";

      if (totalMinutes < 1) {
        className = "text-body"; // Regular color
      } else if (totalMinutes >= 1 && totalMinutes < 15) {
        className = "text-success"; // Green color
      } else {
        className = "text-danger"; // Red color
      }

      return `<b class="${className}">` + `${hoursStr}${minutesStr}${secondsStr}`.trim() + `</b>`;
    },

    formatSystemEvent(e) {
      return e.Event;
    },

    formatMedicalEvent(e) {
      const left = Math.round((e.Sum_L - e.Expected_Sum_L) * 100 / e.Expected_Sum_L);
      const right = Math.round((e.Sum_R - e.Expected_Sum_R) * 100 / e.Expected_Sum_R);
      return `<b>${e.Event}</b> Left ${left}% / Right ${right}%`;
    },

    formatUserEvent(e) {
      return e.Event;
    },

    toggleInsightsIcon() {
      this.lightbulbActive = !this.lightbulbActive
      if (this.lightbulbActive)
        setTimeout(this.toggleInsightsIcon, 200);
      else setTimeout(this.toggleInsightsIcon, 3000);

    },

    toggleEventType() {
      const currentIndex = this.eventTypes.indexOf(this.eventType);
      const nextIndex = (currentIndex + 1) % this.eventTypes.length;
      this.eventType = this.eventTypes[nextIndex];
    },

    reset() {
      this.pedisolSession.splice(0);
      for (let x in this.pedisol) delete this.pedisol[x];
      this.pedisol = {L: [], R: []};
      this.selectedRange = [0, 0];
      this.insights = [];
    },

    saveInsights() {
      // Logic to save the insights to Firestore
      this.insights.forEach(async (insight) => {
        const insightData = {
          insight: insight.text,
          isAi: true,
          seen: new Date(),
          selectedRange: this.selectedRange,
          session: this.selectedSession.sessionId,
          t: Date.now(),
          token: this.selectedPatient.activation_token
        };
        await this.saveInsight(insightData);
      });

      this.$bvToast.toast("Insights saved successfully.", {
        variant: "success",
        ...this.toasterSettings
      });

      this.insightsModal = false; // Close the modal
    },

    discardInsights() {
      this.$bvToast.toast("Insights discarded.", {
        variant: "danger",
        ...this.toasterSettings
      });

      this.insightsModal = false; // Close the modal
    },

    async saveInsight(insightData) {
      try {
        await addDoc(collection(db, "insights"), insightData);
      } catch (error) {
        console.error("Error saving insight:", error);
      }
    }

  },

  watch: {
    mode(newMode) {

      this.reset();

      if (this.unsubscribe) {
        this.unsubscribe()
      }

      if (newMode === this.modes.REALTIME) {
        this.loadRealtime();
      }

      if (newMode === this.modes.INSIGHTS) {
        this.loadInsights();
      }
    },

    selectedSession() {
      this.reset();
      this.loadGait();
    },

    selectedRange(value) {
      if (value[0] !== this.previousRange[0]) {
        this.pedisol = this.pedisolSession[value[0]];
      }

      if (value[1] !== this.previousRange[1]) {
        this.pedisol = this.pedisolSession[value[1]];
      }

      this.previousRange = [...value];
    },

  },

  computed: {
    mappedEvents() {
      return this.events.filter(e => {
        return this.eventType === 'all' || e.Type === this.eventType;
      }).map(e => {
        let data = {
          labelClass: 'badge-secondary',
          labelText: 'Unknown Event',
          description: 'No description available.',
          timestamp: this.formatTimestamp(e.T)
        }
        switch (e.Type) {
          case "system":
            data.labelClass = 'badge-warning'; // Yellow for technical events
            data.labelText = this.$t('general.system');
            data.description = this.formatSystemEvent(e);
            break;
          case "medical":
            data.labelClass = 'badge-danger'; // Red for medical events
            data.labelText = this.$t('general.medical');
            data.description = this.formatMedicalEvent(e);
            break;
          case "user":
            data.labelClass = 'badge-primary'; // Red for medical events
            data.labelText = this.$t('general.user');
            data.description = this.formatUserEvent(e);
            break;
        }
        return data;
      });
    },

    valL() {
      if (this.pedisol?.L.length !== this.pedisolSize) return new Array(this.pedisolSize).fill(0)
      return this.pedisol.L
    },

    valR() {
      if (this.pedisol?.R.length !== this.pedisolSize) return new Array(this.pedisolSize).fill(0)
      return this.pedisol.R
    },

    spatialTemporalParameters() {
      // Initialize counters
      let leftStanceCount = 0;
      let leftSwingCount = 0;
      let rightStanceCount = 0;
      let rightSwingCount = 0;
      let doubleSupportCount = 0;
      let totalCount = 0;

      const selectedSessionData = this.pedisolSession.slice(this.selectedRange[0], this.selectedRange[1] + 1);
      if (!this.pedisolSession.length) return {
        L: {
          stance: 0,
          swing: 0,
        },
        R: {
          stance: 0,
          swing: 0
        },
        doubleSupport: 0
      };

      // Iterate through each item in the selected session data
      selectedSessionData.forEach((item) => {
        if (item.LState && item.LState !== "N/A" && item.RState && item.RState !== "N/A") { // Ensure LState and RState are defined
          // Check and count phases for the left foot
          if (stancePhases.includes(item.LState)) {
            leftStanceCount++;
          } else if (swingPhases.includes(item.LState)) {
            leftSwingCount++;
          }

          // Check and count phases for the right foot
          if (stancePhases.includes(item.RState)) {
            rightStanceCount++;
          } else if (swingPhases.includes(item.RState)) {
            rightSwingCount++;
          }

          // Check for Double Support (both feet in stance phases)
          if (stancePhases.includes(item.LState) && stancePhases.includes(item.RState)) {
            doubleSupportCount++;
          }

          totalCount++;
        }
      });

      if (totalCount === 0) totalCount = 1;

      // Calculate the percentage of time spent in each phase for each foot and in Double Support
      return {
        L: {
          stance: ((leftStanceCount / totalCount) * 100).toFixed(1),
          swing: ((leftSwingCount / totalCount) * 100).toFixed(1),
        },
        R: {
          stance: ((rightStanceCount / totalCount) * 100).toFixed(1),
          swing: ((rightSwingCount / totalCount) * 100).toFixed(1)
        },
        doubleSupport: ((doubleSupportCount / totalCount) * 100).toFixed(1)
      };
    },

    scalingFactor() {
      // Default patient weight to 80 kg if not defined
      const patientWeight = this.selectedPatient.weight_kg || 80;
      // Each sensor's max capacity (4 kg)
      const sensorMaxCapacity = 4;
      // Calculate foot capacity (either 40 or 30 sensors per foot)
      const footCapacity = this.pedisolSize * sensorMaxCapacity;
      // Total capacity across both feet
      const totalSensorCapacity = 2 * footCapacity;
      // Scaling factor to normalize sensor readings
      return patientWeight * 2 / totalSensorCapacity;
    },

    balancePosition() {
      if (typeof this.pedisol === 'undefined') return 50;

      const totalLeft = this.pedisol.L.reduce((acc, val) => acc + val, 0);
      const totalRight = this.pedisol.R.reduce((acc, val) => acc + val, 0);
      const total = totalLeft + totalRight;

      if (total === 0) return 50; // Center if there's no data

      // Calculate percentage offset from the center
      return (totalRight / total) * 100; // From 0% (fully left) to 100% (fully right)
    },

    filteredSessions() {
      if (this.tagSearch.length > 1) {
        return this.sessions.filter(s => {
          return s.Tags?.join('').includes(this.tagSearch);
        });
      }
      return this.sessions;
    },

    groupedSensors() {
      if (typeof this.pedisol === 'undefined') return {L: 0, R: 0};
      if (!this.switchGroupSensors) return this.pedisol;

      // Use the new method to group the sensor data
      return this.groupSensors(this.pedisol);
    },

    isSelf() {
      return this.selectedPatient.id === this.$store.state.user.id;
    },

    isValidTag() {
      return /^[a-zA-Z0-9]+$/.test(this.tagText);
    },

    modeTitle() {
      switch (this.mode) {
        case 0:
          return this.$t("pages.pedisteps.patientRealtimeGait");
        case 1:
          return this.$t("pages.pedisteps.patientGait");
        case 2:
          return this.$t("pages.pedisteps.patientEvents");
        case 3:
          return this.$t("pages.pedisteps.patientInsights");
        default:
          return "";
      }
    },

    generateMarks() {
      // Check if pedisolSession has data
      if (!this.pedisolSession.length) return;

      return this.pedisolSession.reduce((acc, item, index) => {
        // Only mark points of interest where RState is "Pre-Swing"
        if (item.RState === "Pre-Swing") {
          // Calculate time in mm:ss from start
          const secondsFromStart = Math.floor(item.T / 1000); // assuming T is in milliseconds
          const minutes = Math.floor(secondsFromStart / 60);
          const seconds = secondsFromStart % 60;
          // Add the mark
          acc[index] = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        }
        return acc;
      }, {});
    }

  },

  mounted() {

    const userId = this.$store.state.user.id
    const accessToken = this.$store.state.user.access_token

    const firstOption = this.timeOptions[4];
    this.selectedTimeOption = firstOption.label;
    this.selectedTimestamp = firstOption.value;

    setTimeout(this.toggleInsightsIcon, 3000)

    this.selectPatient(this.$store.state.user);

    this.getPatients(userId, accessToken).then(response => response.json()).then(
        (results) => {
          this.setPatients({patients: results.patients});
          this.setLastSession();
        }
    )
  },

  beforeDestroy() {
    // Stop listening to changes
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }
}
</script>
